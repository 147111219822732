import { ajaxRequest, setCookie, getCookie } from './utils';

(function () {
  const initFacebookMessanger = () => {
    window.addEventListener('load', () => {
      if (getCookie('fb-chat')) {
        loadScript();
        //   FB.XFBML.parse();
      }
    });

    const btn = document.querySelector('.fb-customerchat-button');
    btn?.addEventListener('click', function () {
      this.classList.add('animate');
      loadScript();
      // FB.XFBML.parse();
      setCookie('fb-chat', true, 24 * 60 * 60 * 1000 * 0.0216);
    });
    window.fbAsyncInit = () => {
      console.log('fbAsyncInit');
      FB.Event.subscribe('customerchat.load', () => {
        console.log('customerchat.load');
        // console.log('showDialog');
        FB.CustomerChat.show();
      });
      FB.Event.subscribe('customerchat.show', () => {
        console.log('customerchat.show');
        FB.CustomerChat.showDialog();
      });
      FB.Event.subscribe('customerchat.dialogShow', () => {
        console.log('customerchat.dialogShow');
        if (btn) {
          btn.style.display = 'none';
        }
      });
      FB.init({
        xfbml: false,
        version: 'v6.0',
        autoLogAppEvents: true,
      });
      FB.XFBML.parse(undefined, () => {
        console.log('parse completed');
      });
    };
    // https://connect.facebook.net/sv_SE/sdk/xfbml.customerchat.js#xfbml=1&version=v6.0&autoLogAppEvents=1
    const loadScript = () => {
      btn?.classList.add('loading');
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/sv_SE/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    };
  };

  const initAndelar = () => {
    const andelarList = document.querySelectorAll('.andelarlist.section');

    andelarList.forEach((andelar) => {
      const listId = andelar.dataset.listId;
      const filters = andelar.querySelectorAll('.andelar__filters a');
      const container = andelar.querySelector('.andelar__content');
      const deselectBtn = andelar.querySelector('.deselect-all');

      const getSelectedFilters = () => {
        return Array.from(filters)
          .filter((filter) => filter.classList.contains('selected'))
          .map((filter) => filter.getAttribute('href').replace('?type=', ''));
      };

      const load = (callback) => {
        container.classList.add('loading');
        const types = getSelectedFilters();

        if (types.length) {
          deselectBtn.style.display = 'flex';
        } else {
          deselectBtn.style.display = 'none';
        }

        ajaxRequest(
          theme_js.ajaxurl,
          {
            action: 'andelar_load',
            listId,
            types,
          },
          (response, err) => {
            try {
              const { success, data } = JSON.parse(response);
              if (success && data) {
                container.innerHTML = data;
              }
            } catch (err) {}
            container.classList.remove('loading');
            callback();
          }
        );
      };

      deselectBtn.addEventListener('click', (e) => {
        const selectedFilters = Array.from(filters).filter((filter) =>
          filter.classList.contains('selected')
        );

        selectedFilters.forEach((selectedFilter) => {
          selectedFilter.classList.add('disabled');
          selectedFilter.classList.remove('selected', 'one');
        });

        load(() => {
          selectedFilters.forEach((selectedFilter) => {
            selectedFilter.classList.remove('disabled');
          });
        });
      });

      filters.forEach((filter) => {
        filter.addEventListener('click', (e) => {
          e.preventDefault();
          filter.classList.add('disabled');
          filter.classList.toggle('selected');

          load(() => {
            filter.classList.remove('disabled');
          });
        });
      });
    });
  };

  const initMenu = () => {
    const body = document.body,
      bMenu = document.getElementsByClassName('b-menu')[0],
      bContainer = document.getElementsByClassName('b-container')[0],
      bNav = document.getElementsByClassName('b-nav')[0];
    bMenu?.addEventListener(
      'click',
      () => {
        [body, bContainer, bNav].forEach((n) => {
          n.classList.toggle('open');
        });
      },
      !1
    );

    const primaryMenu = document.querySelector('.header__primary');
    const burgerBtn = document.querySelector('.header__toggle-btn');
    burgerBtn.addEventListener(
      'click',
      () => {
        primaryMenu.classList.toggle('opened');
        setCookie(
          'primary_menu_opened',
          primaryMenu.classList.contains('opened') ? 1 : 0,
          30 * 12
        );
      },
      false
    );
  };

  const initFaq = () => {
    const faqItemEls = document.querySelectorAll('.faq__item');
    const openClass = 'opened';
    faqItemEls.forEach((item) => {
      item.addEventListener('click', () => {
        const container = item.querySelector('.faq__item-content');
        if (!item.classList.contains(openClass)) {
          item.classList.add(openClass);
          container.style.height = 'auto';
          let height = container.clientHeight + 'px';
          container.style.height = '0px';
          setTimeout(() => {
            container.style.height = height;
          }, 0);
        } else {
          container.style.height = '0px';
          container.addEventListener(
            'transitionend',
            () => {
              item.classList.remove(openClass);
            },
            {
              once: true,
            }
          );
        }
      });
    });
  };

  const initCountdownTimer = () => {
    const countdownTimers = document.querySelectorAll('.countdown-timer__item');
    if (countdownTimers.length) {
      const dateDiff = (date1, date2) => {
        // get total seconds between the times
        let delta = Math.abs(date1 - date2) / 1000;
        // calculate (and subtract) whole days
        const days = Math.floor(delta / 86400);
        delta -= days * 86400;
        // calculate (and subtract) whole hours
        const hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;
        // calculate (and subtract) whole minutes
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        // what's left is seconds
        const seconds = delta % 60; // in theory the modulus is not required
        return {
          day: days,
          hour: hours,
          minute: minutes,
          second: seconds,
          diff: (date1 - date2) / 1000,
        };
      };
      const timers = [];

      countdownTimers.forEach((i) => {
        const datetimeEnd = i.dataset.end;
        if (datetimeEnd) {
          const utcEndDatetime = new Date(
            Date.UTC.apply(this, datetimeEnd.split(','))
          );
          const timerFunc = () => {
            const currentDate = new Date();
            const utcCurrentDate = new Date(currentDate.toUTCString());
            const dateDiffObj = dateDiff(utcEndDatetime, utcCurrentDate);
            if (dateDiffObj.diff > 0) {
              if (dateDiffObj.day === 0) {
                if (i.classList.contains('countdown-timer__item--days')) {
                  i.classList.remove('countdown-timer__item--days');
                  i.classList.add('countdown-timer__item--hours');
                }
              } else {
                delete dateDiffObj.second;
              }
              for (const k in dateDiffObj) {
                const v = dateDiffObj[k];
                const text = `${v}`.length === 1 ? '0' + v : v;
                const el = i.querySelector(`.time-part.${k}`);
                const prevText = el?.innerText;
                if (el && prevText !== `${text}`) {
                  el.innerHTML = text;
                }
              }
            }
            return dateDiffObj.diff;
          };
          timerFunc();
          timers[i] = setInterval(() => {
            const dateDiff = timerFunc();
            if (dateDiff <= 0) {
              clearInterval(timers[i]);
            }
          }, 100);
        }
      });
    }
  };

  const initLoadMore = () => {
    const btnsLoadMore = document.querySelectorAll('.btn-load-more');
    btnsLoadMore.forEach((btnLoadMore) => {
      btnLoadMore?.addEventListener('click', () => {
        const parentParent = btnLoadMore.parentElement?.parentElement;
        const table = parentParent.querySelector('table');
        const tbody = table?.querySelector('tbody');
        const itemsCount = tbody?.children.length;
        const authorId = btnLoadMore.dataset.authorId;
        const type = btnLoadMore.dataset.type;
        btnLoadMore.disabled = true;
        ajaxRequest(
          theme_js.ajaxurl,
          {
            action: 'author_load_more',
            itemsCount: itemsCount,
            authorId: authorId,
            type: type,
          },
          (response, err) => {
            try {
              const resp = JSON.parse(response);
              if (resp.success && resp.data) {
                const posts = resp.data.posts;
                if (!posts.length || resp.data.has_next === false) {
                  btnLoadMore.remove();
                }
                posts.forEach((v) => {
                  const tr = document.createElement('tr');
                  v.forEach((v2) => {
                    const tempTr = document.createElement('tr');
                    tempTr.innerHTML = v2;
                    tr.appendChild(tempTr.firstChild);
                  });
                  tbody.appendChild(tr);
                });
              }
            } catch (err) {}
            btnLoadMore.disabled = false;
          }
        );
      });
    });
  };

  document.addEventListener('DOMContentLoaded', () => {
    initAndelar();
    initMenu();
    initFaq();
    initCountdownTimer();
    initLoadMore();

    // let initialSidebarPos = false;
    // let fixed = false;
    // const el = document.querySelector('.single-odds .sidebar');
    // if (!el) return;
    // const sidebarContainer = el.parentElement;
    // let sidebarContainerHeight = 0;
    // let previousTop = 0;
    // const topOffset = 30;

    // const resetElement = () => {
    //   el.style.position = '';
    //   el.style.top = '';
    // };
    // const getSidebarHeight = () => {
    //   sidebarContainerHeight = sidebarContainer.getBoundingClientRect().height;
    // };
    // const writeLayout = () => {
    //   el.style.top = previousTop + 'px';
    // };

    // let windowWidth = window.innerWidth;
    // getSidebarHeight();
    // window.addEventListener('resize', () => {
    //   const windowWidth = window.innerWidth;
    //   if (windowWidth < 992) {
    //     requestAnimationFrame(resetElement);
    //   }
    // });
    // window.addEventListener('scroll', (e) => {
    //   if (windowWidth >= 992) {
    //     const scrollTop = window.scrollY;
    //     const top = $el.getBoundingClientRect().top + window.scrollY;
    //     const elHeight = $el.clientHeight;
    //     if (!fixed) {
    //       if (scrollTop >= top - topOffset) {
    //         initialSidebarPos = top - topOffset;
    //         fixed = true;
    //       }
    //     } else {
    //       if (scrollTop < initialSidebarPos) {
    //         fixed = false;
    //       }
    //     }
    //     if (fixed) {
    //       getSidebarHeight();
    //       if (
    //         scrollTop - initialSidebarPos <
    //         sidebarContainerHeight - elHeight
    //       ) {
    //         const newTop = scrollTop - initialSidebarPos;
    //         if (previousTop !== newTop) {
    //           previousTop = newTop;
    //           requestAnimationFrame(writeLayout);
    //         }
    //       }
    //     } else {
    //       requestAnimationFrame(resetElement);
    //     }
    //   }
    // });
  });
  initFacebookMessanger();
})();
