export const ajaxRequest = (url, data, callback) => {
  try {
    const x = new XMLHttpRequest();
    x.open('POST', url, 1);
    x.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    x.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    x.onreadystatechange = () => {
      x.readyState > 3 && callback && callback(x.responseText, x);
    };
    const formParts = [];
    for (let prop in data) {
      const val = data[prop];
      formParts.push(`${prop}=${encodeURIComponent(val)}`);
    }
    x.send(formParts.join('&'));
  } catch (e) {
    console.error(e);
    callback(null, e);
  }
};

export const setCookie = (name, value, days) => {
  var expires = '';

  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }

  document.cookie = name + '=' + value + expires + '; path=/';
};

export const getCookie = (name) => {
  var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
};
